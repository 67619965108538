import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  Check,
  Checks,
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Plus,
  Prohibit,
  Receipt,
  X,
} from "@phosphor-icons/react"
import {
  Avatar,
  AvatarGroup,
  Button,
  Checkbox,
  CodenameTag,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  SegmentedControl,
  Tag,
  Toast,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr, minifyUuid } from "@vesatogo/utils"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { OrdersCustom } from "~/constants/icons"
import { APIRoutes, AppRoutes } from "~/constants/routes"
import { TRADE_KINDS, TwilioMessages } from "~/constants/static.items"
import {
  Order_By,
  Receipt_Bool_Exp,
  UsersQuery,
  useAllReceiptsQuery,
  useAuthenticatedUserQuery,
  useReceiptFinalTrades2Mutation,
} from "~/generated/graphql"
import { config } from "~/pages/pavti/config"
import { downloadPdf } from "~/utils/downloadPdf"

import { pick } from "lodash-es"
import ReceiptActions from "~/components/ReceiptActions"
import { useReceiptSelectorList } from "~/store/receipts-selector-store"
import ReceiptsFilter from "../ReceiptsFilter"
import { TradeKinds } from "~/constants/Kinds"
export function downLoadReceipt(id: string | number) {
  return () => {
    const token = localStorage.getItem("token")
    downloadPdf(APIRoutes.downloadTradeReceipt(id), token).catch(err => {
      return toast.custom(
        <Toast title={"Unable to download the receipt"} intent="danger" />
      )
    })
  }
}

export function addHishobPatti(id?: string[]) {
  const [, receiptFinalTrades] = useReceiptFinalTrades2Mutation()
  const navigate = useNavigate()
  return async () => {
    const { error } = await receiptFinalTrades({
      ids: id,
    })
    if (error) {
      return toast.error(error?.message)
    }
    navigate(`${AppRoutes.tradeReceipts}?updatedAt=true`)
    return toast.success("Successfully added Hishob Patti")
  }
}
const COMMON_FIELDS = [
  "id",
  "seller",
  "total_amount",
  "total_quantity",
  "commodity",
]

const COLUMNS = [
  {
    accessor: "id",
    Header({ data }) {
      const selectable = data?.filter(d => d.id)
      const { selected, selectAll, reset } = useReceiptSelectorList()
      const isSelectAll =
        Object.keys(selected)?.length &&
        Object.keys(selected)?.length === selectable.length
      return (
        <Checkbox
          labelClassName="!mb-4"
          checked={isSelectAll ? true : false}
          onChange={() => {
            isSelectAll
              ? reset()
              : selectAll(selectable?.map(data => pick(data, COMMON_FIELDS)))
          }}
        />
      )
    },
    Cell({ value, row }) {
      const { selected, setSelected } = useReceiptSelectorList()
      if (!row?.original?.id) return null
      return (
        <Checkbox
          checked={selected[value] ? true : false}
          onChange={() => {
            setSelected(pick(row.original, COMMON_FIELDS))
          }}
        />
      )
    },
  },
  {
    Header: <div className="pl-3">Receipt No</div>,
    accessor: "name",
    Cell({ row: { original } }) {
      if (!original.id) return "--"
      return (
        <div className="pl-4">
          {original?.kind === TradeKinds.Booking &&
          original?.hishob_patti_count?.aggregate?.count > 0 ? (
            <Tag>
              <Link
                className="text-left"
                to={AppRoutes.tradeReceiptDetail(original.id)}
              >
                Receipt #{original.id}
              </Link>
            </Tag>
          ) : (
            <Link
              className="link-text text-left"
              to={AppRoutes.tradeReceiptDetail(original.id)}
            >
              Receipt #{original.id}
            </Link>
          )}
        </div>
      )
    },
  },
  {
    Header: "Type",
    accessor: "kind",
    Cell({ value }) {
      const kind = TRADE_KINDS.find(k => k.id === value)
      return kind?.name || "--"
    },
  },
  {
    Header: "Tracking code",
    accessor: "tracking_code",
    Cell({ value }) {
      if (!value) return "--"
      return minifyUuid(value)
    },
  },
  {
    Header: "Created On",
    accessor: "created_at",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={dayjs(value).format("D MMM 'YY")}
          subtitle={dayjs(value).format("hh:mm a")}
        />
      )
    },
  },
  {
    Header: "Farmer",
    accessor: "seller.user[0]",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={
            <div className="flex items-center gap-1">
              {value.full_name}{" "}
              {value?.is_blocked && (
                <Prohibit color="red" size={20} weight="fill" />
              )}
            </div>
          }
          subtitle={<CodenameTag referenceId={value.external_reference_id} />}
        />
      )
    },
  },
  {
    Header: "Traders",
    accessor: "trades",
    Cell({ value }) {
      if (!value?.length) return "--"
      return (
        <AvatarGroup>
          {value.map(value => {
            const user = value?.buyer?.user?.[0]
            if (!user) return null
            const blockStyle = user?.is_blocked
              ? {
                  border: "2px solid #F13434",
                  backgroundColor: "#ffe6e6", 
                  color: "#F13434",
                }
              : undefined

            return (
              <Tooltip
                content={
                  <div>
                    <div>{user.full_name}</div>
                    <div>{user.external_reference_id}</div>
                  </div>
                }
                key={value.id}
              >
                <Avatar
                  style={blockStyle}
                  name={user.full_name}
                  src={user?.photo?.url}
                />
              </Tooltip>
            )
          })}
        </AvatarGroup>
      )
    },
  },
  {
    Header: "Total Quantity",
    accessor: "total_quantity",
    Cell({ value, row: { original } }) {
      if (!value && value !== 0) return "--"
      return (
        <span>
          {value || "0"}{" "}
          <span className="text-gray-500">
            {original.trades?.[0]?.trade_items[0]?.quantity_unit?.name || "na"}
          </span>
        </span>
      )
    },
  },
  {
    Header: "Total Amount",
    accessor: "total_amount",
    Cell({ value }) {
      if (!value) return "--"
      return <Cell title={inr(value)} />
    },
  },
  {
    Header: "Sub Receipt Count",
    accessor: "child_receipts_aggregate.aggregate.count",
    Cell({ value }) {
      if (!value || value === 0) return "--"
      return (
        <Tag intent="info" pill variant="minimal" className="font-600">
          {value}
        </Tag>
      )
    },
  },
  {
    Header: "Msg Status",
    accessor: "twilio_message.status",
    Cell({ value }) {
      const twilioStatus = TwilioMessages[value]
      if (twilioStatus)
        return (
          <div className="flex gap-1">
            {twilioStatus.id === "failed" ? (
              <X size={20} weight="bold" color={twilioStatus.color} />
            ) : (
              <Checks size={20} weight="bold" color={twilioStatus.color} />
            )}
            {twilioStatus.name}
          </div>
        )
      if (value) return value
      return "--"
    },
  },
  {
    Header: "Placed By",
    accessor: "created_by",
    Cell({ value, row }) {
      if (!value) return "--"
      return (
        <Tooltip
          content={
            <div>
              <p>{value?.full_name || "na"}</p>
              <p className="text-gray-400">
                {dayjs(row?.original?.created_at).format("DD MMM 'YY | h:mm a")}
              </p>
            </div>
          }
        >
          <Avatar
            className="relative text-center"
            name={value?.full_name}
            src={value?.photo?.url}
          />
        </Tooltip>
      )
    },
  },
  {
    Header: "",
    accessor: "updated_at",
    Cell({ row: { original } }) {
      const navigate = useNavigate()
      return (
        <Popover
          minimal
          arrow={false}
          className="p-0"
          trigger="mouseenter"
          interactive
          animation="fade"
          theme="light-border"
          placement="bottom"
          content={
            <Menu className="max-h-52">
              <MenuItem
                onClick={() => {
                  navigate(AppRoutes.tradeReceiptDetail(original.id))
                }}
              >
                View Details
              </MenuItem>
              <MenuItem onClick={downLoadReceipt(original.id)}>
                Download Receipt
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate({
                    pathname: AppRoutes.tradeReceiptDetail(original.id),
                    search: `?newfpr=${original.id}`, // newfpr = new from parent receipt query
                  })
                }}
              >
                Add New Receipt
              </MenuItem>
              <MenuItem
                disabled={
                  original?.kind !== TradeKinds.Booking ||
                  original?.hishob_patti_count?.aggregate?.count > 0
                }
                onClick={addHishobPatti([original.id])}
              >
                Add Hishob Patti
              </MenuItem>
            </Menu>
          }
        >
          <button className="hover:bg-gray-300 rounded">
            <DotsThreeVertical size={20} />
          </button>
        </Popover>
      )
    },
  },
]

export type FilterType = {
  sb_seller?: UsersQuery["base_user"][0]
  sb_buyer?: UsersQuery["base_user"][0]
  com_commodity?: any
  com_variety?: any
  oth_mode?: any
  oth_kind?: any
  oth_status?: any
  oth_facilitator?: any
  oth_created_at?: any
}
const TradeReceipts = () => {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const [{ data: aData }] = useAuthenticatedUserQuery()
  const search = params.get("search")
  const updatedAt = params.get("updatedAt")
  const page = Number(params.get("page")) || 1
  const [filter, setFilter] = useState<FilterType>({})
  const [debouncedSearch] = useDebouncedValue(search?.trim(), config.debounce)
  const canViewTrade = useAuthorization(Permission.ViewTrade)
  const canViewInvoice = useAuthorization(Permission.ViewInvoice)
  const buyerCode = filter.sb_buyer?.codename
  const sellerCode = filter.sb_seller?.codename
  const commodityCode = filter.com_commodity?.codename
  const varietyCode = filter.com_variety?.codename
  const facilitatorCode = filter.oth_facilitator?.codename
  const currentDeptCodename = aData?.me?.agent?.current_department?.codename
  const [department] = useLocalStorage({ key: "department" })
  const createdAt = filter?.oth_created_at
  const whereClause: Receipt_Bool_Exp = {
    ...(debouncedSearch
      ? Number(debouncedSearch)
        ? { id: { _eq: Number(debouncedSearch) } }
        : { tracking_code: { _ilike: `%${debouncedSearch}%` } }
      : {}),
    kind: { _eq: filter.oth_kind },
    created_at: createdAt
      ? {
          _lte: dayjs(createdAt).endOf("day").toISOString(),
          _gte: dayjs(createdAt).startOf("day").toISOString(),
        }
      : undefined,
    department: {
      reference_id: {
        _eq: department || currentDeptCodename,
      },
    },
    seller: sellerCode
      ? {
          reference_id: {
            _eq: sellerCode,
          },
        }
      : undefined,
    trades: {
      buyer: buyerCode
        ? {
            reference_id: {
              _eq: buyerCode,
            },
          }
        : undefined,
    },
    commodity: commodityCode && {
      reference_id: {
        _eq: commodityCode,
      },
    },
    commodity_variety: varietyCode && {
      reference_id: {
        _eq: varietyCode,
      },
    },
    mode: {
      _eq: filter.oth_mode,
    },
    facilitator: facilitatorCode && {
      reference_id: {
        _eq: facilitatorCode,
      },
    },
  }

  const [{ data, fetching }, refetch] = useAllReceiptsQuery({
    variables: {
      limit: config.pageSize,
      offset: (page - 1) * config.pageSize,
      order_by: { created_at: Order_By.DescNullsLast },
      where: whereClause,
    },
    pause: !department && !currentDeptCodename,
    requestPolicy: "network-only",
  })

  const totalReceipts = data?.receipt_aggregate?.aggregate?.count || 0
  useEffect(() => {
    if (updatedAt) {
      refetch()
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"receipts"}
          onChange={value => {
            if (value === "trades" && canViewTrade) {
              navigate(AppRoutes.tradeRequests)
            } else if (value === "invoices" && canViewInvoice) {
              navigate(AppRoutes.tradeInvoices)
            }
          }}
          data={[
            canViewTrade && {
              label: "Trades",
              value: "trades",
              icon: <List className="mr-1" />,
            },
            {
              label: "Receipts",
              value: "receipts",
              icon: <Receipt className="mr-1" />,
            },
            canViewInvoice && {
              label: "Invoices",
              value: "invoices",
              icon: <OrdersCustom className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search by tracking code",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
          <ReceiptsFilter filter={filter} setFilter={setFilter} />
        </div>
        <div className="flex gap-3">
          {/* <Button
            onClick={() => {
              params.set("update", "all")
              setParams(params)
            }}
            variant="outline"
          >
            Update {totalTrades} trades
          </Button> */}
          <ReceiptActions />
          {!department && !currentDeptCodename ? null : (
            <Button onClick={() => navigate("new")} leftIcon={<Plus />}>
              Receipt
            </Button>
          )}
        </div>
      </header>
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        getRowProps={({ original }) => ({
          className: original?.seller?.user[0]?.is_blocked || original?.trades.some(trade => trade?.buyer?.user[0]?.is_blocked)
            ? "bg-red-100 hover:bg-red-100"
            : "",
        })}
        paginationProps={{
          total: Math.ceil(totalReceipts / config.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={data?.receipt || []}
        meta={<>{totalReceipts} receipts</>}
      />
    </>
  )
}

export default TradeReceipts
