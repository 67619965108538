import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  Circle,
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Receipt,
} from "@phosphor-icons/react"
import {
  Avatar,
  CodenameTag,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  SegmentedControl,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import dayjs from "dayjs"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RemarkModal } from "~/components/Remark/RemarkModal"
import { HistoryColumns } from "~/components/Transaction/TransactionHistory"
import { TransactionModal } from "~/components/Transaction/TransactionModal"
import { PayableReceivableDate } from "~/constants/Payable"
import { siteConfig } from "~/constants/config"
import { AppRoutes } from "~/constants/routes"
import {
  Order_By,
  useAuthenticatedUserQuery,
  useInvoiceSettlementDetailQuery,
  useInvoiceSettlementQuery,
  useUpdateInvoiceSettlementPaymentMutation,
} from "~/generated/graphql"
import PayableFilter, {
  PayableFilterType,
} from "~/pages/d/payable/PayableFilter"
import { usePayableSelector } from "~/pages/d/payable/PayableSelector"
import { inrFormatter } from "~/utils/inrFormatter"

const historyColumns = [
  HistoryColumns.DATE?.accessor,
  HistoryColumns.PAYMENT_OPT?.accessor,
  HistoryColumns.PAYMENT_MODE?.accessor,
  HistoryColumns.TRANSACTION_ID?.accessor,
  HistoryColumns.RELEASED_AMOUNT?.accessor,
  HistoryColumns.NARRATION?.accessor,
]
export const InvoiceSettlement = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<PayableFilterType>({})
  const [params, setParams] = useSearchParams()
  const page = Number(params.get("page")) || 1
  const id = params.get("invoiceId")
  const search = params.get("search")
  const [department] = useLocalStorage({ key: "department" })
  const { selected } = usePayableSelector()
  const isTrade = selected === "trade"
  const [debouncedSearch] = useDebouncedValue(
    search?.trim(),
    siteConfig.debounce
  )
  const [{ data: authData }] = useAuthenticatedUserQuery()
  const me = authData?.me

  const [{ data, fetching: listFetching }, refetchList] =
    useInvoiceSettlementQuery({
      variables: {
        where: {
          ...(filter?.placed_by
            ? {
                created_by_id: {
                  _eq: filter?.placed_by?.id?.toString(),
                },
              }
            : undefined),
          created_at: {
            _lte: filter?.date_to
              ? dayjs(filter?.date_to).endOf("d")
              : undefined,
            _gte: filter?.date_from
              ? dayjs(filter?.date_from).startOf("d")
              : undefined,
            _gt: dayjs(PayableReceivableDate).startOf("d"),
          },

          buyer: filter?.seller?.codename
            ? {
                reference_id: {
                  _eq: filter?.seller?.codename,
                },
              }
            : undefined,

          ...(debouncedSearch
            ? {
                id:
                  debouncedSearch && Number(debouncedSearch)
                    ? {
                        _eq: Number(debouncedSearch),
                      }
                    : undefined,
              }
            : undefined),
          ...(debouncedSearch && !Number(debouncedSearch)
            ? {
                _or: debouncedSearch
                  ? [
                      {
                        department: debouncedSearch
                          ? {
                              name: {
                                _ilike: `%${debouncedSearch}%`,
                              },
                            }
                          : undefined,
                      },
                      {
                        buyer: debouncedSearch
                          ? {
                              name: {
                                _ilike: `%${debouncedSearch}%`,
                              },
                            }
                          : undefined,
                      },
                    ]?.filter(abc => abc)
                  : undefined,
              }
            : undefined),
        },
        limit: siteConfig.pageSize,
        offset: (page - 1) * siteConfig.pageSize,
        orderBy: {
          created_at: Order_By.DescNullsLast,
        },
      },
    })

  const [{ data: detailData, fetching }] = useInvoiceSettlementDetailQuery({
    variables: {
      id: id,
    },
    pause: !id,
    requestPolicy: "network-only",
  })

  const totalRows = data?.invoice_aggregate?.aggregate?.count || 0

  const totalAmount = data?.invoice
    ?.reduce((acc, inv) => acc + (Number(inv?.buyer_payable) || 0), 0)
    .toFixed(2)

  const totalPaid = data?.invoice
    ?.reduce(
      (acc, inv) =>
        acc +
        (Number(inv?.transactions_aggregate?.aggregate?.sum?.amount) || 0),
      0
    )
    .toFixed(2)

  const totalRemaining = Number(totalAmount) - Number(totalPaid)

  const COLUMNS = [
    {
      Header: "Created On",
      accessor: "created_at",
      Cell({ value }) {
        // if (!value) return "--"
        return (
          <Cell
            title={dayjs(value).format("D MMM 'YY")}
            subtitle={dayjs(value).format("hh:mm a")}
          />
        )
      },
      formatter: () => {
        return "Total"
      },
    },
    {
      Header: "Invoice No.",
      accessor: "id",
      Cell({ value, row: { original } }) {
        if (!value) return "--"
        return (
          <div className="pl-4">
            <div
              className="link-text text-left w-fit cursor-pointer"
              onClick={() => {
                params.set("transaction", "true")
                params.set("invoiceId", original?.id)
                setParams(params)
              }}
            >
              Invoice #{value}
            </div>
          </div>
        )
      },
    },
    {
      Header: "Plant Name",
      accessor: "department.name",
      Cell({ value }) {
        return <Cell title={value} />
      },
    },
    {
      Header: "Customer",
      accessor: "buyer.user.0",
      Cell({ value }) {
        return (
          <Cell
            title={value.full_name}
            subtitle={
              <CodenameTag referenceId={value.external_reference_id2} />
            }
          />
        )
      },
    },
    {
      Header: "Invoice Value",
      accessor: "buyer_payable",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 !text-green" title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },
    {
      Header: "Received Amount",
      accessor: "transactions_aggregate.aggregate.sum.amount",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 !text-green" title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },
    {
      Header: "Receivable Amount",
      id: "receivable",
      Cell({ row: { original } }) {
        const received =
          original?.transactions_aggregate?.aggregate?.sum?.amount
        const total = original?.buyer_payable
        const remaining = Number((total - received).toFixed(2))
        return (
          <Cell
            titleClassName="font-700 !text-red"
            title={inr(remaining || 0)}
          />
        )
      },
      formatter: () => {
        return inrFormatter.format(totalRemaining)
      },
    },

    {
      Header: "Placed By",
      accessor: "created_by",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.full_name}
              src={value?.photo?.url}
            />
          </Tooltip>
        )
      },
    },
    {
      Header: "Transaction Status",
      accessor: "transactions",
      Cell({ row: { original }, value }) {
        const totalAmount = original?.buyer_payable
        const paid = original?.transactions_aggregate?.aggregate?.sum?.amount
        const remaining = Number((totalAmount - paid).toFixed(2))
        const isSettled = remaining === 0 || remaining < 0
        return (
          <Cell
            titleClassName={classNames(
              "flex gap-1 items-center font-600 ",
              isSettled ? "!text-green" : paid ? "!text-blue" : "!text-yellow"
            )}
            title={
              <>
                <Circle weight="fill" />
                {isSettled ? "Settled" : paid ? "Partial" : "Pending"}
              </>
            }
          />
        )
      },
    },
    {
      Header: "Last Transaction",
      accessor: "transactions.0.date",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell title={<>{dayjs(value).format("DD MMM 'YYYY")}</>} />
      },
    },
    {
      Header: "Remark",
      accessor: "remark",
      Cell({ value }) {
        return <>{value}</>
      },
    },
    {
      Header: "",
      accessor: "updated_at",
      Cell({ row: { original } }) {
        const [params, setParams] = useSearchParams()

        return (
          <Popover
            minimal
            arrow={false}
            className="p-0"
            trigger="mouseenter"
            interactive
            placement="bottom"
            content={
              <Menu className="max-h-72">
                <MenuItem
                  onClick={() => {
                    params.set("remark", original.id)
                    setParams(params)
                  }}
                >
                  Remark
                </MenuItem>
              </Menu>
            }
          >
            <button className="hover:bg-gray-300 rounded">
              <DotsThreeVertical size={20} />
            </button>
          </Popover>
        )
      },
    },
  ]
  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"invoice"}
          onChange={value => {
            if (value === "plant") {
              navigate(AppRoutes.plantSettlement)
            }
          }}
          data={[
            {
              label: "Plant Settlement",
              value: "plant",
              icon: <List className="mr-1" />,
            },
            {
              label: "Invoice Settlement",
              value: "invoice",
              icon: <Receipt className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            leftElement={<MagnifyingGlass />}
            inputProps={{
              placeholder: "Search by Invoice No., Customer name ",
              value: search || "",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
          />
          <PayableFilter
            filter={filter}
            setFilter={setFilter}
            hideAdvanceStatus
            hideAdvanceType
            hideTransactionStatus
          />
        </div>
        <div className="flex "></div>
      </header>
      <TransactionModal
        refetch={refetchList}
        totalAmount={detailData?.invoice_by_pk?.buyer_payable || 0}
        transactions={detailData?.invoice_by_pk?.transactions}
        headerTotalAmount={detailData?.invoice_by_pk?.buyer_payable}
        objectId={detailData?.invoice_by_pk?.id}
        sellerName={
          detailData?.invoice_by_pk?.buyer?.user?.[0]?.full_name || ""
        }
        fetching={fetching}
        historyCols={`!grid-cols-5`}
        idLabel={"Invoice No"}
        historyColSpan={`!col-span-3`}
        invoiceId={detailData?.invoice_by_pk?.id}
        columns={historyColumns}
        hideBank
      />
      <RemarkModal
        useAddRemarkMutation={useUpdateInvoiceSettlementPaymentMutation}
        refetch={refetchList}
        title="Remark"
      />
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        paginationProps={{
          total: Math.ceil(totalRows / siteConfig.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={listFetching}
        columns={COLUMNS}
        data={data?.invoice || []}
        meta={<>{totalRows} results</>}
        aggregateColumnClassName={
          "bg-blue-100 hover:bg-blue-100 p-10 sticky bottom-0 z-[8] font-600"
        }
      />
    </>
  )
}

export default InvoiceSettlement
