import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { CaretDown } from "@phosphor-icons/react"
import { useReportModal } from "@vesatogo/grass-reportmodal"
import { useAllDepartmentsQuery } from "~/generated/graphql"
const PayableActions = () => {
  const onOpen = useReportModal(store => store.onOpen)
  return (
    <Popover
      minimal
      arrow={false}
      className="p-0"
      trigger="mouseenter"
      interactive
      animation="fade"
      theme="light-border"
      placement="bottom"
      content={
        <Menu className="max-h-52">
          <MenuItem
            onClick={() => {
              onOpen(
                [
                  {
                    key: "from_date",
                    label: "Date From",
                    type: "date",
                  },
                  {
                    key: "till_date",
                    label: "Date Till",
                    type: "date",
                  },
                  {
                    key: "payable_report_name",
                    label: "Payable Report Name",
                    placeholder: "Select Payable Report Name",
                    type: "staticSelect",
                    options: [
                      {
                        id: "advance_outstanding_report",
                        name: "Advance Outstanding Report",
                      },
                      {
                        id: "plant_settlement_report",
                        name: "Plant Settlement Report",
                      },
                      {
                        id: "payable_settlement_report",
                        name: "Payable Settlement Report",
                      },
                      {
                        id: "payable_settlement_outstanding_report",
                        name: "Payable Settlement Outstanding Report",
                      },
                      {
                        id: "receivable_settlement_report",
                        name: "Receivable Settlement Report",
                      },
                      {
                        id: "receivable_settlement_outstanding_report",
                        name: "Receivable Settlement Outstanding Report",
                      },
                    ],
                  },
                  {
                    key: "department",
                    label: "Department",
                    placeholder: "Select Department",
                    type: "querySelect",
                    dataHook: useAllDepartmentsQuery,
                  },
                ],
                `https://${
                  import.meta.env.VITE_REST_URL
                }/reports/payable_receivable_report_email`,
                {
                  multipleEmails: true,
                  successMessage: "Payable/Receivable Report sent successfully",
                  
                  
                }
              )
            }}
          >
            Payable Receivable Report
          </MenuItem>
        </Menu>
      }
    >
      <Button
        className="!bg-gray-300"
        intent="none"
        leftIcon={<CaretDown weight="fill" />}
      >
        Action
      </Button>
    </Popover>
  )
}

export default PayableActions
