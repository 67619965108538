import { Prohibit } from "@phosphor-icons/react"
import { FormQuerySelector } from "@vesatogo/grass-core"
import { FormCard } from "~/components/FormCard"
import UserDetailsCard from "~/components/UserDetailsCard"
import { Permission } from "~/constants/Permissions"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { useAuthenticatedUserQuery, useUsersQuery } from "~/generated/graphql"

type WhoIsTradingProps = {
  showBuyer?: boolean
  inputProps?: any
  form?: any
  isDisabled?: boolean
  setPackingOpen?: () => void
  disableBuyer?: boolean
}

function getOptionLabel({ is_verified, name, is_blocked }) {
  const statusLabel =
    is_verified === false
      ? "Rejected"
      : is_verified === true
      ? "Verified"
      : "Pending Verification"

  return (
    <div className="flex justify-between">
      <div>
        <p>
          {name} | {statusLabel}
        </p>
      </div>
      {
        is_blocked && (
            <Prohibit size={22} color="red" weight="fill" />
        )

      }
    </div>
  )
}

export const WhoIsTrading = ({
  form,
  inputProps,
  showBuyer = true,
  isDisabled,
  setPackingOpen,
  disableBuyer,
}: WhoIsTradingProps) => {
  const [{ data }] = useAuthenticatedUserQuery()
  const me = data?.me

  const buyerGroups = ["Trader"]
  if (me?.permissions?.includes(Permission.SelectFarmerInBuyer)) {
    buyerGroups.push("Farmer")
  }

  const customStyles = {
    option: (base: any, state: any) => {
      const isBlocked = state.data.is_blocked

      return {
        ...base,
        cursor: isBlocked ? "not-allowed" : "pointer",
        backgroundColor: isBlocked
          ? "#FFDDDD"
          : state.isSelected
          ? "#799700"
          : state?.isFocused
          ? "#DDE2C8"
          : undefined,

        color: isBlocked ? "#F13434" : state.isSelected ? "white" : undefined,
      }
    },
  }

  return (
    <FormCard
      title="Who is trading"
      action={
        setPackingOpen ? (
          <button onClick={setPackingOpen} className="text-blue-500 underline">
            Packing Material
          </button>
        ) : null
      }
    >
      <div className="flex gap-4">
        <div className={"w-1/2"}>
          <FormQuerySelector
            required
            isDisabled={isDisabled}
            label="Farmer"
            styles={customStyles}
            className={"mb-2"}
            serverSideQuery
            dataHook={useUsersQuery}
            variables={{ groups: ["Farmer", "Trader"] }}
            getOptionLabel={getOptionLabel}
            isOptionDisabled={(option: any) => option.is_blocked}
            {...inputProps("seller", {
              group: SharedEntityGroup.KiteUser,
            })}
          />
          <UserDetailsCard user={form.values.seller} />
        </div>
        {showBuyer && (
          <div className={"w-1/2"}>
            <FormQuerySelector
              required={true}
              isDisabled={disableBuyer ?? isDisabled}
              label="Trader"
              styles={customStyles}
              className={"mb-2"}
              serverSideQuery
              dataHook={useUsersQuery}
              variables={{ groups: buyerGroups }}
              getOptionLabel={getOptionLabel}
              {...inputProps("buyer", {
                group: SharedEntityGroup.KiteUser,
              })}
            />

            <UserDetailsCard user={form.values.buyer} />
          </div>
        )}
      </div>
    </FormCard>
  )
}
